
import { Context } from '@nuxt/types'
import { defineComponent } from 'vue'
import MachinationsLightPage from '~/domain/pages/MachinationsLightPage'
import { DiagramStoreActions, DiagramTypes } from '~/domain/interfaces'
import { PageData } from '@interfaces/PageDataTypes'

export default defineComponent({
  name: 'homepage',
  extends: MachinationsLightPage,
  layout: 'machinations',
  head() {
    // @ts-ignore
    const pageHead = this.pageHead()
    const pageData = this.pageData as PageData
    const yoastDescription = pageData?.yoast_head_json?.description
    return {
      ...pageHead,
      title: `The Ultimate Game Design Platform`,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'The Ultimate Game Design Platform',
        },
        {
          hid: 'description',
          name: 'description',
          content: yoastDescription,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: yoastDescription,
        },
        ...pageHead?.meta,
      ],
    }
  },
  async asyncData(context: Context) {
    const { $config, store } = context
    const { wpBaseURL } = $config

    await store.dispatch('common/setupHomepage', context)
    const pageData = await store.dispatch('wp/fetchPageBySlug', {
      context,
      slug: 'homepage',
    })
    await store.dispatch('wp/getPosts', { context, wpBaseURL })
    await store.dispatch(DiagramStoreActions.GET_DIAGRAMS_OF_THE_MONTH, context)

    return {
      pageData
    }
  },
  methods: {
    preventContextMenu(event: Event) {
      if (event && event.target) {
        const button = (event.target as HTMLElement).closest('.v-btn') as HTMLElement;
        if (button) {
          event.preventDefault();
        }
      }
    },
  },
  computed: {
    highlightedDiagrams() {
      return this.$store.state.diagrams?.highlighted
    },
    DiagramTypes() {
      return DiagramTypes
    },
  },
  mounted: function () {
    document.addEventListener('contextmenu', this.preventContextMenu);
  },
  beforeDestroy: function () {
    document.removeEventListener('contextmenu', this.preventContextMenu);
  },
})
